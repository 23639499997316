import { Center, Spinner } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ErrorBoundary } from '@sentry/remix';
import React from 'react';
import { PropsWithChildren, ReactNode, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  fallback?: ReactNode;
};

export const SuspenseWithSpinner = ({ children, fallback }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  return (
    <ErrorBoundary
      fallback={() => <>{t('error.error')}</>}
      onError={(e, componentStack, eventId) =>
        datadogLogs.logger.error(
          'Error occurred in SuspenseWithSpinner',
          {
            componentStack,
            eventId,
          },
          e as Error
        )
      }
    >
      <Suspense
        fallback={
          fallback ? (
            fallback
          ) : (
            <Center w='full' h='full'>
              <Spinner color='primary.500' />
            </Center>
          )
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};
